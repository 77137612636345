import MobileDetect from 'mobile-detect';

var imagesLoaded = require('imagesloaded');
var fullpage = require('./common/fullpage.min.js');

let header = null;
let paperVideo = null;
let content = null;
let news = null
let participants = null
let bookImage = null;
let ballVideo = null;
let forestImage = null;
let forestVideo = null;

const calculateVideoPosition = function() {
    // header = $('#header');
    // paperVideo = $('.paper-video');
    // content = $('#fullpage');
    // news = $('#news');
    // participants = $('#participants');
    // bookImage = $('.book-image');
    // ballVideo = $('.ball-video');
    // forestImage = $('.grass-image');
    // forestVideo = $('.forest-video');

    // if (paperVideo !== null && header !== null) {
    //     paperVideo[0].style.top = Math.ceil(Math.abs(window.innerHeight - paperVideo[0].getBoundingClientRect().height) / 2) + 'px';
    // }

    // if (ballVideo !== null && bookImage !== null && content !== null) {
    //     let ballTop = Math.ceil(news[0].getBoundingClientRect().height + participants[0].getBoundingClientRect().height - (bookImage[0].getBoundingClientRect().height / 2));

    //     ballVideo[0].style.top = ballTop + 'px';

    //     if (forestImage !== null && forestVideo !== null) {
    //         let forestTop = ballVideo[0].getBoundingClientRect().height + ballTop;
    //         let _height = content[0].getBoundingClientRect().height - forestImage[0].getBoundingClientRect().height - (bookImage[0].getBoundingClientRect().height / 2);

    //         if (_height > forestTop) {
    //             forestTop = Math.ceil(_height + (forestImage[0].getBoundingClientRect().height / 2));
    //         }

    //         // forestVideo[0].style.top = forestTop + 'px';
    //     }
    // }
};

$(function(){
    imagesLoaded('#container', function() {
        // $('.carousel').carousel({
        //     interval: 5000
        // });

        $("body").on("click", ".goArrow", function(e){
            var target = $(this).attr("href");

            if($(target).length > 0){
                $("body, html").animate({
                    scrollTop: $(target).offset().top - 130
                }, 600, "linear");
            }
            e.preventDefault();
        });

        $("body").on("click", "#menuBtn", function(e){
            var target = $(this).data("target");

            $(target).toggleClass("show");
            $(this).toggleClass("open");
            $("body").toggleClass("hidebar");

            // $("#header").toggleClass("open");
            $("#mainmenu").toggleClass("show");
            e.preventDefault();
        });

        $("body").on("click", ".gotop", function(e){
            $("body, html").animate({
                scrollTop: 0
            }, 600);
            e.preventDefault();
        });

        $(window).on('scroll', throttle(toggleHeader,50))

        if($(".winner-item").length > 0){
            $(".tab-pane.active").find(".winner-item").each(function(index,item){
                setTimeout(() => {
                    $(item).addClass("show");
                }, 200 * index);

            });
        }

        // 得獎作品 nav bar
        if($(window).width() > 767){
            if ($(".tab-nav.active").find("li.active").length > 0) {
                
                $(".tab-nav.active .tab-nav-bar ").css("left",$(".tab-nav.active").find("li.active").position().left + 35).addClass('d-block')
                
            }
        } 
        $('.tab-nav li:first-child').on('click', function(){
            if($(window).width() < 768){
                $(this).closest('.tab-nav').toggleClass('active');
            }
        });
     
 
        $(".tab-nav").on("click", "a", function(e){
            $(".winner-item").removeClass("show");
            $(".tab-nav").find("li.active").removeClass("active");
            $(this).parents("li").addClass("active");

            if(!$(this).parents(".tab-nav").hasClass("active")){
                $(".tab-nav").removeClass("active");
                $(this).parents(".tab-nav").addClass("active");
            }

            var target = $(this).data("bs-target");
            $("#award-tabContent").find(".active").removeClass("show").removeClass("active");
            $(target).addClass("active").addClass("show");

            setTimeout(function(){
                $(target).find(".winner-item").each(function(index,item){
                    setTimeout(() => {
                        $(item).addClass("show");
                    }, 200 * index);

                });
            }, 150)

            e.preventDefault();
        })

        $(".tab-nav").on("mouseover","a", function(){
            var target = $(this).parents(".tab-nav");
            var targetLi = $(this).parent("li");
            target.find('.tab-nav-bar').addClass('d-block')
            
            target.find(".tab-nav-bar").stop(true,true).animate({
                "left": targetLi.position().left + 35
            }, 200);
        })

        $(".tab-nav").on("mouseout","a", function(){
            var target = $(this).parents(".tab-nav");
            var targetLi = $(this).parent("li");

            if(!targetLi.hasClass("active")){
                $(".tab-nav-bar").stop(true,true).animate({
                    "left": $(".tab-nav.active").find("li.active").position().left + 35
                },200);
            } 
        })

        $(window).on("resize", function(){
            if($(window).width() > 767){
                if ($(".tab-nav.active").find("li.active").length > 0) {
                    $(".tab-nav.active .tab-nav-bar").css("left",$(".tab-nav.active").find("li.active").position().left + 35).show();   
                }
            }else{
                $(".tab-nav-bar").hide();
        
            }
        })

        svgimg();
    }); // end imagesLoaded

    if (document.getElementById('fullpage')) {
        setTimeout(calculateVideoPosition, 300);
    }

    const md = new MobileDetect();

   

    $('.mainmenu').on('click', function(e) {
        if ($(".index-page").length === 1) {
            if (md.mobile() || md.phone() || window.innerWidth < 768) {

                var target = $(this).data("target");
                if($(target).length > 0){
                    $("body, html").animate({
                        scrollTop: $(target).offset().top - 130
                    }, 600, "linear");
                }

                e.preventDefault();
            }
            if (e.target.innerText === '參賽辦法' && $('#guideline').css('display') !== 'none'){
                $('.method-side-bar ul li a.active').removeClass('active');
                $('.method-side-bar ul li a').index(0).classList.add('active');
            }
            if ($("#mainmenu").hasClass("show")) {
                $("#mainmenu").removeClass("show");
                $("#menuBtn").removeClass("open");
                $("body").removeClass("hidebar");
            }
        }
        
        if($(".index-page").length > 0){
            e.preventDefault();
            
            $("#mainmenu").find(".active").removeClass("active");
            $(this).addClass("active");
            const id = $(this).data('url');
            const url = $(this).attr('href');

            window.history.pushState({}, $(this).attr('title') + ' - 教科圖書設計獎 schoolbook design award 2021', url);
            document.title = $(this).attr('title') + ' - 教科圖書設計獎 schoolbook design award 2021';

            $('.fullpage-section').each(function(index, item) {
                $(item).css('display', 'none');
                $(item).css('opacity', '0');
            });

            const target = document.querySelector('#' + id);

            if (target) {
                target.style.display = 'block';
                setTimeout(function() {
                    target.style.opacity = 1;
                }, 300);
            }
        }
    });

    // $('a[class="navbar-brand"]').on('click', function(e) {
    //     e.preventDefault();

    //     window.history.pushState({}, '教科圖書設計獎 schoolbook design award 2021', '/');

    //     $('.fullpage-section').each(function(index, item) {
    //         $(item).css('display', 'none');
    //         $(item).css('opacity', '0');
    //     });

    //     const target = document.querySelector('#intro');

    //     if (target) {
    //         target.style.display = 'block';
    //         target.style.opacity = 1;
    //     }
    // });

    $('.fullpage-section').each(function(index, item) {
        $(item).css('display', 'none');
        $(item).css('opacity', '0');
    });

    const path = window.location.pathname.split('/');

    if (path.length > 1) {
        let target = null

        if (['prize','news', 'guideline', 'judge_year', 'winner_year'].indexOf(path[1]) > -1) {
            target = document.querySelector('#' + path[1]);
            
            if (target) {
                target.style.display = 'block';
                target.style.opacity = 1;
            }
        } else {
            target = document.querySelector('#intro')

            if (target) {
                target.style.display = 'block'
                target.style.opacity = 1
            }
        }
    } else {
        const target = document.querySelector('#intro');

        if (target) {
            target.style.display = 'block';
            target.style.opacity = 1;
        }
    }
    $(".hassub").on("click", ".mainmenu-link", function (e) {
        if ($(window).width() < 992) {
            $(this).parents(".hassub").toggleClass("open");
            e.preventDefault();
        }
    })
    // if (md.mobile() || md.phone() || window.innerWidth < 768 || null === document.getElementById('fullpage')) {
    //     // Mobile or Phone do not enable fullpage
    //     window.addEventListener('scroll', function() {
    //         if ($(window).scrollTop() >= 10) {
    //             $('.navbar').addClass('onscroll');
    //         } else {
    //             $('.navbar').removeClass('onscroll');
    //         }
    //     }, false);

    //     if ($(window).scrollTop() >= 10) {
    //         $('.navbar').addClass('onscroll')
    //     } else {
    //         $('.navbar').removeClass('onscroll')
    //     }

    //     const hash = window.location.hash.replace('-section', '');

    //     if (hash !== '' && document.querySelector(hash)) {
    //         $("body, html").animate({
    //             scrollTop: $(hash).offset().top - 130
    //         }, 600, "linear");
    //     }
    // } else {
    //     if (document.getElementById('fullpage')) {
    //         const fullpageApi = new fullpage('#fullpage', {
    //             licenseKey: 'F93D402F-429644DC-AE5D5C4D-18DB4E09',
    //             lockAnchors: false,
    //             anchors: [
    //                 'intro-section',
    //                 'news-section',
    //                 'participants-section',
    //                 'award-section',
    //                 'judge-section',
    //                 'winner-section',
    //                 'footer-section',
    //             ],
    //             navigation: true,
    //             navigationPosition: 'right',
    //             slidesNavigation: true,
    //             slidesNavPosition: 'left',
    //             sectionSelector: '.fullpage-section',
    //             fitToSection: false,
    //             normalScrollElements: '.judge-modal, .modal-backdrop',
    //             onLeave: function (origin, dest, dir) {
    //                 if (dir === 'down') {
    //                     if (origin.anchor === 'intro-section') {
    //                         $('.navbar').addClass('onscroll')
    //                     }
    //                 } else {
    //                     if (dest.anchor === 'intro-section') {
    //                         $('.navbar').removeClass('onscroll')
    //                     }
    //                 }
    //             },
    //         })
    //     }
    // }

    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const target = entry.target;
                
                $(target).find('.cover-img').each(function() {
                    $(this).attr('style', $(this).data('style'));
                });
                if ($(target).find('.index-award').length > 0) {
                    $(target).find('.index-award').find('img').each(function() {
                        $(this).attr('src', $(this).data('src'));
                    }); 
                }
                observer.unobserve(entry.target); 
            }
        });
    });

    const lazySections = document.querySelectorAll('.fullpage-section');
    lazySections.forEach(lazySection => {
        observer.observe(lazySection);
    })
});

if (document.getElementById('fullpage')) {
    $(window).on('resize', function () {
        calculateVideoPosition();
    });
}

function svgimg(){
	$('img.svgimg').each(function(){
        var $img = $(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        jQuery.get(imgURL, function(data) {
            // Get the SVG tag, ignore the rest
            var $svg = jQuery(data).find('svg');

            // Add replaced image's ID to the new SVG
            if(typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if(typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass+' replaced-svg');
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');

            // Check if the viewport is set, else we gonna set it if we can.
            if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
            }

            // Replace image with new SVG
            $img.replaceWith($svg);

        }, 'xml');

    });
}
const throttle = (func, frame = 100) => {
    let last = 0;
    return (e) => {
        const now = new Date();
        if (now - last >= frame) {
            func(e);
            last = now;
        }
    };
};
let prevScrollY = 0;
function toggleHeader() {
    const currentScrollY = window.scrollY;
    const header = document.getElementById('header');
    const navbar = document.querySelector('.navbar');
    if(header ){
        if (currentScrollY > prevScrollY) {
            navbar.classList.add('hide');
        } else {
            navbar.classList.remove('hide');
        }
        prevScrollY = currentScrollY;
    } 
}